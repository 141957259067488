import * as SliderPrimitive from "@radix-ui/react-slider";
import { cx } from "class-variance-authority";
import * as React from "react";

const Slider = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={cx(
            "relative flex w-full touch-none select-none items-center",
            className,
        )}
        {...props}
    >
        <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary-50">
            <SliderPrimitive.Range className="absolute h-full bg-primary" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="focus-visible:ring-ring block h-4 w-4 cursor-grab rounded-full border border-primary-100 bg-primary shadow outline outline-2 outline-white transition-colors focus-visible:outline-none focus-visible:ring-1 active:cursor-grabbing disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
