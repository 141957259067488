import { Button } from "@/components/Button";
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogSheet,
    DialogTitle,
    DialogTrigger,
} from "@/components/DialogSheet";
import {
    AdjustmentsFilled,
    ArrowLeftUnfilled,
    CloseUnfilled,
} from "@/components/Icons";
import { Section } from "@/components/Section";
import { Separator } from "@/components/Separator";
import { Slider } from "@/components/Slider";
import { captureException } from "@sentry/nextjs";
import type { TFunction } from "i18next";
import type { FormEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type FilterFormValues = { byMaxDistance: number[] };
type TForm = TFunction<["components/SearchPage", "common"]>;
const SearchFilterLocationForm = ({
    t,
    onSubmit,
    disabled,
}: {
    t: TForm;
    onSubmit: (v: FilterFormValues["byMaxDistance"][0]) => void;
    disabled: boolean;
}): JSX.Element => {
    const { control, resetField, handleSubmit } =
        useFormContext<FilterFormValues>();
    const handleSubmitWithoutPropagation = handleSubmit(
        (v, e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();

            const d = v.byMaxDistance[0];
            if (d === undefined || d < 10 || d > 30) {
                const customError = new Error("Invalid distance value");
                customError.name = "InvalidValueError";
                captureException(customError, (ctx) =>
                    ctx.setExtras({
                        value: d,
                        formValues: v,
                    }),
                );
                return;
            }

            onSubmit(d);
        },
    );
    const onClear = (e): void => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(10);
        resetField("byMaxDistance", { defaultValue: [10] });
    };

    return (
        <form
            className="flex h-full flex-col lg:gap-8"
            onSubmit={handleSubmitWithoutPropagation}
        >
            <div className="flex flex-1 flex-col gap-3 overflow-y-auto lg:overflow-y-visible">
                <Controller
                    control={control}
                    name="byMaxDistance"
                    rules={{ min: 10, max: 30 }}
                    render={({ field: { value, onChange } }) => (
                        <fieldset className="flex flex-col gap-4">
                            <div className="flex items-end justify-between">
                                <label
                                    className="typography-main font-bold text-blue-grey-900"
                                    htmlFor="maxDistance"
                                >
                                    {t("distance", "Distance")}
                                </label>
                                <span className="typography-sub text-blue-grey-400">
                                    {`${value} km`}
                                </span>
                            </div>
                            <Slider
                                disabled={disabled}
                                defaultValue={value}
                                onValueChange={onChange}
                                min={10}
                                max={30}
                            />
                        </fieldset>
                    )}
                />
            </div>
            <div className="mt-auto grid grid-cols-2 gap-2 lg:m-0">
                <Button variant="outlined" onClick={onClear}>
                    {t("common:clear", "Clear")}
                </Button>
                <Button type="submit" variant="primary">
                    {t("common:apply", "Apply")}
                </Button>
            </div>
        </form>
    );
};

export const FilterDialog = ({
    open,
    onOpenChange,
    onSubmit,
    disabled,
}: {
    open: boolean;
    onOpenChange: (v: boolean) => void;
    onSubmit: (v: FilterFormValues["byMaxDistance"][0]) => void;
    disabled: boolean;
}): JSX.Element => {
    const { t } = useTranslation(["components/SearchPage", "common"]);
    const handleSubmit = (v: FilterFormValues["byMaxDistance"][0]): void => {
        onSubmit(v);
        onOpenChange(false);
    };

    return (
        <DialogSheet open={open} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
                <Button
                    className="flex gap-2 px-3 py-1.5"
                    variant="secondary"
                    size="sm"
                    disabled={disabled}
                >
                    <AdjustmentsFilled className="size-4" />
                    {t("common:filter", "Filter")}
                </Button>
            </DialogTrigger>
            <DialogContent side="right">
                <div className="flex justify-between px-4 pb-6">
                    <DialogClose className="flex items-center justify-between">
                        <ArrowLeftUnfilled />
                    </DialogClose>
                    <DialogTitle>{t("common:filter", "Filter")}</DialogTitle>
                    <div className="size-5" />
                </div>
                <DialogDescription>
                    <SearchFilterLocationForm
                        t={t}
                        onSubmit={handleSubmit}
                        disabled={disabled}
                    />
                </DialogDescription>
            </DialogContent>
        </DialogSheet>
    );
};

export const FilterDesktop = ({
    disabled,
    onSubmit,
    onClose,
}: {
    disabled: boolean;
    onClose?: () => void;
    onSubmit: (v: FilterFormValues["byMaxDistance"][0]) => void;
}): JSX.Element => {
    const { t } = useTranslation(["components/SearchPage", "common"]);
    return (
        <Section className="h-full group-data-[filter=hide]:hidden group-data-[filter=show]:p-4">
            <div className="h-fit">
                <div className="flex items-center justify-between">
                    <h3 className="typography-h3 font-bold text-blue-grey-900">
                        {t("common:filter", "Filter")}
                    </h3>
                    <CloseUnfilled
                        className="size-7 cursor-pointer text-blue-grey-900"
                        onClick={onClose}
                    />
                </div>
                <div className="py-4">
                    <Separator className="text-blue-grey-200" />
                </div>
                <SearchFilterLocationForm
                    t={t}
                    onSubmit={onSubmit}
                    disabled={disabled}
                />
            </div>
        </Section>
    );
};
