import { type FragmentType, getFragment, graphql } from "@/lib/gql";
import { Image } from "@/lib/imgproxy";
import type { PrismicDocument } from "@prismicio/types";
import { cx } from "class-variance-authority";
import Link from "next/link";
import { RichText, type RichTextBlock } from "prismic-reactjs";
import { type MouseEvent, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComboboxInput, type ComboboxOptionsProps } from "./ComboxInput";
import { FilterRadio } from "./Filter";
import { SearchUnfilled } from "./Icons";

type PrismicData = {
    filter: {
        filter_button_en: RichTextBlock[];
        filter_button_my: RichTextBlock[];
        filter_button_zh: RichTextBlock[];
    }[];
    body: {
        primary: {
            title_en: RichTextBlock[];
            title_my: RichTextBlock[];
            title_zh: RichTextBlock[];
        };
        items: {
            colour: string;
            category_title_en: RichTextBlock[];
            category_title_my: RichTextBlock[];
            category_title_zh: RichTextBlock[];
            category_img: RichTextBlock;
        }[];
    }[];
};
type ColourProp = "black" | "white";

export const categoryListCategoriesFragment = graphql(`
    fragment CategoryListCategories on Category {
        uid
        name
    }
`);

export const categoryListVenuesFragment = graphql(`
    fragment CategoryListVenues on Organisation {
        uid
        services
    }
`);

const getText = (richText: RichTextBlock[]): string =>
    RichText.asText(richText);
const defaultEn = (en: string, lng?: string): string =>
    !!lng && lng.trim() !== "" ? lng : en;

const filterAll = "all";
const filterOthers = "other";
export const CategoryList = ({
    categoriesFragment,
    venuesFragment,
    type,
    value,
    onChange,
}: {
    categoriesFragment?: FragmentType<typeof categoryListCategoriesFragment>[];
    venuesFragment?: FragmentType<typeof categoryListVenuesFragment>[];
    type: "link" | "input";
    value?: string | null;
    onChange?: (v?: string | null) => void;
}): JSX.Element => {
    const { i18n, t } = useTranslation(["components/CategoryList", "common"]);
    const lang = i18n.language || "en";
    const [filterButton, setFilterButton] = useState(filterAll);
    const [categoryFilter, setCategoryFilter] = useState("");
    const documentCategories = useContext(CategoryListContext);

    if (
        !documentCategories ||
        !venuesFragment?.length ||
        !categoriesFragment?.length
    ) {
        // TODO: render something else like "No categories found"
        return <div />;
    }

    const venues = getFragment(categoryListVenuesFragment, venuesFragment);
    const categories = getFragment(
        categoryListCategoriesFragment,
        categoriesFragment,
    ).filter((c) => venues.some((v) => v.services.includes(c.uid)));

    const findCategoryId = (name: string): string | undefined =>
        categories.find((c) => c.name.toLowerCase() === name.toLowerCase())
            ?.uid;
    const prismicData = documentCategories.data as PrismicData;

    const filters = prismicData.filter.map((f) => {
        const text = {
            en: getText(f.filter_button_en),
            my: defaultEn(
                getText(f.filter_button_en),
                getText(f.filter_button_my),
            ),
            zh: defaultEn(
                getText(f.filter_button_en),
                getText(f.filter_button_zh),
            ),
        };
        const value = text.en.toLowerCase();
        return {
            label: text[lang],
            value,
            selected: filterButton,
            onChange: () => setFilterButton(value),
        };
    });

    const groupedCategories: CatgorySectionProps[] = prismicData.body.map(
        (b) => ({
            title: {
                en: getText(b.primary.title_en),
                my: defaultEn(
                    getText(b.primary.title_en),
                    getText(b.primary.title_my),
                ),
                zh: defaultEn(
                    getText(b.primary.title_en),
                    getText(b.primary.title_zh),
                ),
            },
            categories: b.items.map((i) => {
                const categoryName = {
                    en: getText(i.category_title_en),
                    my: defaultEn(
                        getText(i.category_title_en),
                        getText(i.category_title_my),
                    ),
                    zh: defaultEn(
                        getText(i.category_title_en),
                        getText(i.category_title_zh),
                    ),
                };
                const categoryId = findCategoryId(categoryName.en);
                const path =
                    type === "link"
                        ? `/explore/category/${categoryName.en}/${categoryId}`
                        : undefined;
                return {
                    categoryId: categoryId,
                    colour: i.colour as ColourProp,
                    text: categoryName,
                    imageUrl: i.category_img.url,
                    path: path,
                };
            }),
        }),
    );
    const categoryIds = groupedCategories.flatMap((gc) =>
        gc.categories.map((c) => c.categoryId),
    );
    const otherCategories = categories.filter(
        (c) => !categoryIds.includes(c.uid),
    );
    if (otherCategories.length > 0) {
        groupedCategories.push({
            title: {
                en: "Other Sports",
                my: "Sukan Lain",
                zh: "其他运动",
            },
            categories: otherCategories.map((c) => {
                const categoryId = c.uid;
                const categoryName = c.name;
                const path =
                    type === "link"
                        ? `/explore/category/${categoryName}/${categoryId}`
                        : undefined;
                return {
                    categoryId: categoryId,
                    colour: "black" as ColourProp,
                    text: { en: c.name, my: c.name, zh: c.name },
                    path: path,
                };
            }),
        });
    }

    const searchOptions: ComboboxOptionsProps[] = groupedCategories.flatMap(
        (gc) =>
            gc.categories
                .filter((a) => !!a.categoryId)
                .map((a) => ({ id: a.categoryId ?? "", text: a.text[lang] })),
    );
    return (
        <div className="flex flex-1 flex-col gap-5 lg:gap-10">
            <div className="flex flex-col gap-3 lg:gap-5">
                <ComboboxInput
                    placeholder={t(
                        "searchBarPlaceholder",
                        "Search sport category",
                    )}
                    options={searchOptions}
                    handleChange={(v) => {
                        setCategoryFilter(v ?? "");
                        setFilterButton(filterAll);
                    }}
                    icon={
                        <SearchUnfilled className="size-5 text-blue-grey-600" />
                    }
                />
                {!categoryFilter && (
                    <div className="flex flex-wrap gap-3">
                        <FilterRadio
                            label={
                                <span className="capitalize">
                                    {t("common:all", "All")}
                                </span>
                            }
                            value={filterAll}
                            selected={filterButton}
                            onChange={() => setFilterButton(filterAll)}
                        />
                        {filters.map((f) => (
                            <FilterRadio key={f.value} {...f} />
                        ))}
                        {otherCategories.length > 0 && (
                            <FilterRadio
                                label={
                                    <span className="capitalize">
                                        {t("common:other", "Other")}
                                    </span>
                                }
                                value={filterOthers}
                                selected={filterButton}
                                onChange={() => setFilterButton(filterOthers)}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className="flex flex-col gap-8 lg:gap-10">
                {groupedCategories
                    .filter(
                        (gc) =>
                            filterButton === filterAll ||
                            gc.title.en.toLowerCase().includes(filterButton),
                    )
                    .map((gc) => (
                        <CatgorySection
                            key={gc.title.en}
                            groupedCategories={gc}
                            categoryFilter={categoryFilter}
                            value={value}
                            handleClick={onChange}
                            lang={lang}
                        />
                    ))}
            </div>
        </div>
    );
};

type Lang = { en: string; my?: string; zh?: string };
type CatgorySectionProps = {
    title: Lang;
    categories: {
        categoryId?: string;
        colour: ColourProp;
        text: Lang;
        imageUrl?: string;
        path?: string;
    }[];
};
const CatgorySection = ({
    groupedCategories,
    categoryFilter,
    value,
    handleClick,
    lang,
}: {
    groupedCategories: CatgorySectionProps;
    categoryFilter: string;
    value?: string | null;
    handleClick?: (v?: string | null) => void;
    lang: string;
}): JSX.Element => {
    const categories = groupedCategories.categories.filter(
        (c) =>
            !!c.categoryId &&
            (c.text.en.toLowerCase().includes(categoryFilter.toLowerCase()) ||
                c.categoryId === categoryFilter),
    );
    if (categories.length < 1) return <></>;
    return (
        <div className="flex flex-col gap-5">
            <div className="typography-h4 font-semibold text-blue-grey-900">
                {groupedCategories.title[lang]}
            </div>
            <div className="flex flex-wrap gap-3">
                {categories.map((c) => (
                    <ActivityDiv
                        key={c.categoryId + groupedCategories.title.en}
                        {...c}
                        selected={value === c.categoryId}
                        onClick={() => handleClick?.(c.categoryId)}
                        lang={lang}
                    />
                ))}
            </div>
        </div>
    );
};

const ActivityDiv = ({
    text,
    imageUrl,
    colour = "black",
    path,
    selected,
    onClick,
    lang,
}: {
    text: Lang;
    imageUrl?: string;
    colour?: ColourProp;
    path?: string;
    selected: boolean;
    onClick?: () => void;
    lang: string;
}): JSX.Element => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        onClick?.();
    };
    const child = (
        <div
            className={cx(
                "relative size-[100px] cursor-pointer rounded-xl bg-contain shadow-sm outline outline-1 outline-transparent active:bg-primary-200",
                selected
                    ? "bg-primary-200 hover:bg-primary-300"
                    : "bg-primary-50 hover:bg-primary-100 hover:outline-primary-500",
            )}
        >
            {imageUrl && (
                <div className="absolute">
                    <Image
                        alt={text[lang]}
                        src={imageUrl}
                        className="rounded-xl"
                        objectFit="cover"
                        width="100px"
                        height="100px"
                    />
                </div>
            )}
            <span
                className={cx(
                    "relative flex h-full w-full rounded-xl px-3 pt-3 text-left capitalize",
                    colour === "white"
                        ? "typography-h5 bg-gradient-to-b from-[rgba(30,29,38,0.7)] to-[rgba(30,29,38,0)] font-bold text-white"
                        : "typography-h6 text-blue-grey-900",
                )}
            >
                {text[lang]}
            </span>
        </div>
    );
    if (path)
        return (
            <Link href={path}>
                <a className="remove-styles-a">{child}</a>
            </Link>
        );

    return (
        <button
            type="button"
            className="border-none bg-transparent p-0"
            onClick={handleClick}
        >
            {child}
        </button>
    );
};

// TODO: Should pre-process the PrismicDocument in getStaticProps and we should pass down nicely structured data
// i.e. this context shouldn't be PrismicDocument, it should be
// { filters: { label: LangText; value: string }[]; categories: { key: string; label: LangText; colour: Colour; imgUrl: string }[] }
// and match categories with key === category.name during render
export const CategoryListContext = createContext<PrismicDocument | undefined>(
    undefined,
);
