import { cx } from "class-variance-authority";
import type { HTMLAttributes, ReactNode } from "react";

type SkeletonProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
    loading?: boolean;
    avatar?: boolean;
    title?: boolean;
    paragraph?: { rows?: number };
    children?: ReactNode;
};

const SkeletonItem = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>): JSX.Element => (
    <div
        className={cx("animate-pulse bg-blue-grey-50", className)}
        {...props}
    />
);

const Skeleton = ({
    loading = true,
    avatar = false,
    title = true,
    paragraph = { rows: 3 },
    children,
    className,
    ...props
}: SkeletonProps): JSX.Element => {
    const paragraphRows = paragraph.rows ?? 3;
    if (!loading) return <>{children}</>;
    return (
        <div className={cx("flex gap-4", className)} {...props}>
            <div className={cx(!avatar && "hidden", "relative")}>
                <SkeletonItem className="size-12 rounded-full" />
            </div>
            <div className="flex w-full flex-col gap-4">
                <SkeletonItem
                    className={cx(!title && "hidden", "h-4 w-1/2 rounded-md")}
                />
                {Array.from({ length: paragraphRows }).map((_, index) => (
                    <SkeletonItem
                        // biome-ignore lint/suspicious/noArrayIndexKey: Index is fine
                        key={index}
                        className={`h-4 w-full rounded-md ${index === paragraphRows - 1 ? "w-5/6" : ""}`}
                    />
                ))}
            </div>
        </div>
    );
};

export { SkeletonItem, Skeleton };
